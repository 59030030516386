/* global moment */

angular.module('LeasePilot').controller('DashboardController', [
  '$scope',
  '$rootScope',
  '$mdDialog',
  'DocumentService',
  'ShareService',
  'FormService',
  'CompanyService',
  'BuildingService',
  'DownloadService',
  '$http',
  '$timeout',
  '$window',
  function(
    $scope,
    $rootScope,
    $mdDialog,
    DocumentService,
    ShareService,
    FormService,
    CompanyService,
    BuildingService,
    DownloadService,
    $http,
    $timeout,
    $window,
  ) {
    $scope.isLoaded = false;

    $scope.init = async function() {
      $rootScope.$on('loadUser', () => {
        $scope.company = window.user.company;
      });

      $rootScope.forms = await FormService.query({
        testing: window._testing_assets_enabled,
      });
      $rootScope.buildings = await BuildingService.query({
        dashboard: true,
      });
      
      $scope.isLoaded = true;
      $scope.safeApply();

      window.track.event(new LoadDashboardEvent({
        context: $rootScope.getContext(),
      }));
    };

    $scope.showDialog = function(event, isConformedDeal, companyId) {
      companyId = window.user.company.id;

      var template =
        "<md-dialog><new-document-modal company-id='" +
        companyId +
        "' is-conformed-deal='" +
        isConformedDeal +
        "'></new-document-modal></md-dialog>";

      openDialog(template);
    };

    $scope.openDuplicateDialog = function(companyId) {
      companyId = companyId || '';
      var template =
        '<md-dialog>' +
        "<duplicate-company-modal company-id='" +
        companyId +
        "'>" +
        '</duplicate-company-modal></md-dialog>';

      openDialog(template);
    };

    $scope.newCompanyDialog = function() {
      var template =
        '<md-dialog>' +
        '<new-company-modal>' +
        '</new-company-modal></md-dialog>';

      openDialog(template);
    };

    var openDialog = function(template) {
      $mdDialog.show({
        controller:
          ('DialogController', ['$scope', '$mdDialog', DialogController]),
        template: template,
        parent: angular.element(document.body),
        clickOutsideToClose: true,
        fullscreen: false,
      });

      function DialogController($scope, $mdDialog) {
        $scope.cancel = function() {
          $mdDialog.cancel();
        };
      }
    };

    $scope.confirmDeleteCompany = function(companyId, companyName) {
      var template =
        '<md-dialog>' +
        "<delete-company-modal company-id='" +
        companyId +
        "' company-name='" +
        companyName +
        "'>" +
        '</delete-company-modal></md-dialog>';

      openDialog(template);
    };

    $scope.showConfirmDeleteDocument = function(id, type) {
      var buttons = {
        ok: 'OK',
        cancel: 'Cancel',
      };
      $scope.currentLeaseId = id;
      $scope.currentLeaseType = type;
      $scope.openConfirmModal(
        `You are about to permanently delete a document from LeasePilot.
      Press OK to confirm, or Cancel to keep it in the Archive.`,
        () =>
          $scope.deleteDocument($scope.currentLeaseId, $scope.currentLeaseType),
        null,
        buttons,
      );
    };

    window.showConfirmDeleteDocument = $scope.showConfirmDeleteDocument;

    $scope.openConfirmModal = function(
      msg,
      okCallback,
      cancelCallback,
      buttons,
      redirectUrl,
    ) {
      var confirm = $mdDialog.confirm({
        skipHide: true,
        template:
          '<div class="confirm-modal md-dialog-content">' +
          '<p class="title" >' +
          msg +
          '</p>' +
          '<div class="modal-actions">' +
          '<button ng-if=' +
          _.has(buttons, 'ok') +
          ' class="btn blue-outline" ng-click="dialog.hide()">' +
          buttons.ok +
          '</button>' +
          '<button ng-if=' +
          _.has(buttons, 'cancel') +
          ' class="btn blue active" ng-click="dialog.abort()">' +
          buttons.cancel +
          '</button>' +
          '</div>' +
          '</div>',
        onRemoving: function() {
          if (redirectUrl) {
            window.location.href = redirectUrl;
          }
        },
      });

      $mdDialog.show(confirm).then(
        function confirmPublish() {
          if (okCallback) okCallback();
        },
        function preventPublish() {
          if (cancelCallback) cancelCallback();
        },
      );
    };

    $scope.showExportErrorsDialog = function(
      event,
      leaseId,
      targetStatus,
      errors,
    ) {
      $mdDialog.show({
        template:
          '<md-dialog><show-export-errors-modal ' +
          "lease-id='" +
          leaseId +
          "' " +
          "target-status='" +
          targetStatus +
          "' " +
          "errors='" +
          JSON.stringify(errors) +
          "'>" +
          '</show-export-errors-modal></md-dialog>',
        parent: angular.element(document.body),
        targetEvent: event,
        clickOutsideToClose: true,
        fullscreen: false,
      });
    };

    window.showExportErrorsDialog = $scope.showExportErrorsDialog;

    $scope.displayLocalDate = function(datetimeString) {
      return moment(datetimeString)
        .add(moment().utcOffset(), 'm')
        .format('MM/DD/YYYY');
    };

    $scope.deleteDocument = function(id, type) {
      DocumentService.get({
        id: id,
        type: type,
      }).then(
        function(doc) {
          if (doc != null) {
            doc.delete().then(function() {
              window.location.reload();
            });
          }
        },
        function(error) {
          console.log(error);
        },
      );
    };

    $scope.selectAccount = function(accountId) {
      document.cookie = `company_id=${accountId}; path=/;`;
      window.location.href = '/';
    };
  },
]);
